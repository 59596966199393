<template>
  <custom-dialog
    :visible.sync="visible"
    :title="title"
    @close="colseDialog"
    @submit="submit"
    width="45%"
  >
    <div class="editTitle">基本信息</div>

    <div class="edit-main">
      <el-form
        :model="editForm"
        label-width="120px"
        :rules="rules"
        ref="editVersionForm"
      >
        <el-row >
          <el-col :span="12">
            <el-form-item label="所属项目：" prop="projId">
                <el-select
                  class="block-select"
                  size="small"
                  clearable
                  filterable
                  v-model="editForm.projId"
                  placeholder="请选择所属项目"
                >
                  <el-option
                    v-for="item in projectList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="现场编号：" prop="actNo">
              <el-input
                v-model="editForm.actNo"
                size="small"
                placeholder="请输入现场编号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备编号：" prop="equipNo">
              <el-input
                v-model="editForm.equipNo"
                size="small"
                placeholder="请输入设备编号"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="设备类型：" prop="deviceType">
              <el-select
                class="block-select"
                size="small"
                clearable
                v-model="editForm.deviceType"
                placeholder="请选择设备类型："
              >
                <el-option
                  v-for="item in equipType"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="拆卸人员：" prop="dismOper">
              <el-input
                v-model="editForm.dismOper"
                size="small"
                placeholder="请输入拆卸人员"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="拆卸日期：" prop="dismDate">
              <el-date-picker
                class="date"
                v-model="editForm.dismDate"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择拆卸日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="拆卸原因：" prop="notes">
              <el-input
                class="date"
                v-model="editForm.notes"
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="附件：" prop="staffPic">
              <el-upload
                class="file-uploader"
                :action="`${uploadUrl}File/upload`"
                :data="{ type: 1 }"
                :show-file-list="false"
                :on-success="uploadSuccess"
              >
                <div class="upload-main">
                  <span class="upload-btn">
                    <i class="el-icon-upload"></i>上传附件
                  </span>
                  仅支持上传图片、pdf格式
                </div>
                <div class="file-url-main">
                  <div
                    v-for="(url, index) in editForm.annexUrl"
                    class="file-url-item"
                    :key="index"
                  >
                    <div class="file-url">
                      {{ url.match("--") ? url.split("--")[1] : url }}
                    </div>
                    <span
                      class="file-url-btn el-icon-close"
                      circle
                      @click.stop="delImg(index)"
                    ></span>
                  </div>
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../components/common/CustomDialog.vue";
import config from "../../api/config";
  import {equipType} from "../../config/dataStatus"
//   import versionMange from "../../../api/modules/versionMange";
import basic from '../../api/modules/basic'
export default {
  name: "equipmentDisassemblyEdit",
  components: { CustomDialog },
  props: ["visible", "title",'item'],
  data() {
    return {
      editForm: {},
      rules: {
        actNo: [{ required: true, message: "请输入现场编号", trigger: "blur" }],
        projId: [
          { required: true, message: "请选择所属项目", trigger: "change" },
        ],
        equipNo: [{ required: true, message: "请输入设备编号", trigger: "blur" }],
       
        dismOper: [
          { required: true, message: "请输入拆卸人员", trigger: "blur" },
        ],
        dismDate: [
          { required: true, message: "请选择拆卸日期", trigger: "change" },
        ],
        deviceType: [
          { required: true, message: "请选择设备类型", trigger: "change" },
        ],
        notes: [
          { required: true, message: "请输入拆卸原因", trigger: "blur" },
        ],
      },
      uploadUrl: config.uploadUrl,
    
      equipType: equipType,
      projectList:[]
    };
  },
  watch:{
    item(val){
      if(val.dismId){
        this.editForm=val
        this.editForm.deviceType=Number(val.deviceType)
      }else{
        this.editForm={
          actNo:'',
          projId:'',
          equipNo:'',
          dismOper:'',
          dismDate:'',
          deviceType:'',
          notes:'',
        }
      }
    }
  },
  mounted(){
    this.getProjectList()
  },
  methods: {
    colseDialog() {
      this.$emit("close");
    },
    
    getProjectList(){
      basic.GetProjectList().then(res=>{
       
        res.data.map(item=>{
          this.projectList.push({
            label:item.projName,
            value:item.projId
          })
        })
        
      })
    },
    submit() {
      this.$refs.editVersionForm.validate((valid) => {
        if (valid) {
          if(this.editForm.dismId){
            basic.updateDismantle(this.editForm).then((res) => {
            this.$message({
              message: "编辑成功",
              type: "success",
            });
            this.$emit("done");
          });
          }else{
            basic.addDismantle(this.editForm).then((res) => {
            this.$message({
              message: "新增成功",
              type: "success",
            });
            this.$emit("done");
          });
          }
         
        }
      });
    },
    uploadSuccess(val) {
      if (val.code == 200) {
        // 附件信息，未完待续
        this.editForm.annexUrl.push(val.data);
        this.$forceUpdate();
      }
    },
    delImg(index) {
      this.editForm.annexUrl.splice(index, 1);
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped lang="less">
.editTitle {
  border: 1px solid #eeeeee;
  border-bottom: 0px solid #eeeeee;
  padding: 10px 0 10px 30px;
  border-radius: 6px 6px 0px 0px;
  font-weight: bold;
}
.edit-main {
  padding: 20px;
  border: 1px solid #eeeeee;
  border-radius: 0px 0px 6px 6px;
}
</style>
